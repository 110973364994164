import axios from "axios";



export default axios.create({
baseURL: 'https://zyhmeapi20201010173847.azurewebsites.net/api/Email/ResumeEmail',
params: {
   
    }
});

